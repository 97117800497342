:root {
  --blue_0: #001e64;
  --blue_1: #193787;
  --blue_2: #285fa5;
  --blue_3: #5087c3;
  --blue_4: #7dafd7;
  --blue_5: #afcde6;
  --blue_6: #c8dceb;
  --blue_7: #d7e6f0;
  --blue_8: #e8f0f5;
  --blue_9: #f2f6fa;
  --blue_10: #f8f9fc;

  --orange: #ff8269;
  --orange-hover: #fa6450;
  --orange_light: #ffdcc8; 

  --green: #44d7b6;
  --green-hover: #37c8aa;
  --green_light: #e6fffa;
  --green_light-hover: #c8faf0;

  --yellow: #ffe178;
  --yellow-hover: #fad750;
  --yellow_light: #fffabe;
  --yellow_light-hover: #fff0a5;

  --red: #ff6e78;
  --red-hover: #fa4664;
  --red_light: #ffe1e6;
  --red_light-hover: #ffc8cd;

  --bg: #fcfdff;
  --white: #ffffff;

  --border_radius: 8px;
  --shadow: 0px 1px 5px 0px rgba(0,30,100,0.15);
}

body {
  text-align: center;
  font-family: Roboto, sans-serif;
  background-color: var(--bg);
}

/* h1 {
  font-size: 60pt;
  line-height: 68pt;
  color: #001e64;
}

h2 {
  font-size: 48pt;
  line-height: 56pt;
  color: #001e64;
}

h3 {
  font-size: 32pt;
  line-height: 36pt;
  color: #001e64;
}

h4 {
  font-size: 24pt;
  line-height: 28pt;
  color: #001e64;
}

h5 {
  font-size: 20pt;
  line-height: 24pt;
  color: #001e64;
}

h6 {
  font-size: 18pt;
  line-height: 20pt;
  color: #001e64;
} */

.box {
  background-color: white;
  margin: 12px;
  margin-bottom: 0px;
  padding: 12px;
  border-radius: var(--border_radius);
  box-shadow: var(--shadow);
}